import React from "react";
import Layout from "./layout.js";
import Breadcrumbs from "../component/Breadcrumbs.js";
import TestimonialsSection from "../component/TestimonialsSection.js";
import SectionHeading from "../component/SectionHeading.js";
import cultureIcon1 from "../assets/images/cultureicon1.svg";
import cultureIcon2 from "../assets/images/cultureicon2.svg";
import cultureIcon3 from "../assets/images/cultureicon3.svg";
import growthIcon1 from "../assets/images/growthicon1.svg";
import growthIcon2 from "../assets/images/growthicon2.svg";
import growthIcon3 from "../assets/images/growthicon3.svg";
import leadershipIcon1 from "../assets/images/leadershipicon1.svg";
import leadershipIcon2 from "../assets/images/leadershipicon2.svg";
import empowermentIcon1 from "../assets/images/empowermenticon1.svg"; 
import empowermentIcon2 from "../assets/images/empowermenticon2.svg"; 
import topRightLine from "../../src/assets/images/top-right-line.png";
import IconCard from "../component/IconCard.js";

export default function Careers({pageName}) {

  const testimonialSliderData = [
    {id: 1, text: "My years at IBC from 2016 to 2019 were transformative. I was exposed to a wide array of projects that challenged and inspired me. The mentorship and guidance I received from colleagues and Harsh were invaluable and significantly contributed to my career growth.", name: "Soumi Mukherjee, Account Manager at Kantar"},
    {id: 2, text: "In my one year with IBC, I gained valuable experience learning various consulting approaches and techniques. I've had the opportunity to work on diverse projects spanning sectors from education to technology. I had amazing and supportive co-workers with whom we worked hard and celebrated even harder.", name: "Laboni Pramanik. Assistant Manager at PwC"},
    {id: 3, text: "IBC really helped me to understand the typical nuances of secondary research, how to overcome challenges during a research process, typical projects around multiple topics. Special thanks to Harsh for his guidance throughout my tenure.", name: "Arka Sarkar, Assistant Manager at Lumenci"},
    {id: 4, text: "IBC provided a transformative experience for my professional journey, allowing me to acquire valuable knowledge and skills. Over four years, IBC provided me with opportunities to work on challenging projects and engage with seasoned professionals. The hands-on experience and mentorship were unparalleled, enabling me to navigate the complexities of the business world confidently. IBC fostered a supportive work culture, encouraging creativity and excellence. The feedback and guidance from colleagues and supervisors were invaluable in honing my skills and enhancing my professional growth.", name: "Kavya Srivastava, Senior Researcher at GD Research Center"},
    {id: 5, text: "I have worked in IBC for 1 year. I have worked in various kinds of research projects - medium to complex ones and received guidance through out the past 1 year in all the projects I have worked", name: "Sushmita Saha, Senior Associate at PwC"},
  ]

  return (
    <Layout pageName={pageName}>
      <Breadcrumbs title={pageName} text="Providing a Fulfilling, Exciting, and Nurturing Career Path" />

      <section className="why-ibc-sec sec-padding">
        <div className="container">
          <SectionHeading secName="WHY IBC" secText="What Sets Us Apart" />

          <div className="tab-sec">
            <ul className="nav nav-pills" id="why-ibc">
              <li className="nav-item"><a className="nav-link active" aria-current="page" href="#culture" data-bs-toggle="tab">Culture</a></li>
              <li className="nav-item"><a className="nav-link" href="#growth" data-bs-toggle="tab">Growth</a></li>
              <li className="nav-item"><a className="nav-link" href="#leadership" data-bs-toggle="tab">Leadership</a></li>
              <li className="nav-item"><a className="nav-link" href="#empowerment" data-bs-toggle="tab">Empowerment</a></li>
            </ul>
            
            <div className="inner-tab-content">
              <div className="tab-content">
                <div className="tab-pane active" id="culture">
                  <div className="row inner-card-row">
                    <IconCard md="4" icon={cultureIcon1} title="Strong Bonding" text="Collaborate and build camaraderie in a supportive workplace" />
                    <IconCard md="4" icon={cultureIcon2} title="Open Communication" text="Enjoy transparency and open dialogue at all levels" />
                    <IconCard md="4" icon={cultureIcon3} title="Fun" text="Connect in team dinners, virtual parties, celebrations & activities" />
                  </div>
                </div>

                <div className="tab-pane" id="growth">
                  <div className="row inner-card-row">
                    <IconCard md="4" icon={growthIcon1} title="Diverse Experience" text="Gain exposure to a wide range of projects across various industries" />
                    <IconCard md="4" icon={growthIcon2} title="Recognition" text="Receive acknowledgement and celebration for your hard work and achievements" />
                    <IconCard md="4" icon={growthIcon3} title="Active Mentoring" text="Benefit from focused mentoring, coaching, and guidance to support your development" />
                  </div>
                </div>

                <div className="tab-pane" id="leadership">
                  <div className="row inner-card-row">
                    <IconCard md="4" icon={leadershipIcon1} title="Autonomous Management" text="Lead your projects independently while honing cross-functional leadership skills" />
                    <IconCard md="4" icon={leadershipIcon2} title="Client Engagement" text="Enhance your abilities through direct client interaction and effective negotiation" />
                  </div>
                </div>

                <div className="tab-pane" id="empowerment">
                  <div className="row inner-card-row">
                    <IconCard md="4" icon={empowermentIcon1} title="Personal Fulfillment" text="Be empowered to monitor and address your needs, recommending policy changes as needed" />
                    <IconCard md="4" icon={empowermentIcon2} title="Flexible Autonomy" text="Enjoy the freedom to manage your work-life balance with flexible schedules and remote work options" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TestimonialsSection testimonialSliderData={testimonialSliderData} secText="What some IBC Alumni say about us" />

      <section className="roles-sec sec-padding">
        <div className="container">
          <SectionHeading secName="ROLES" secText="Join Our Team" />
          <div className="inner-content">
            <h6 className="subtitle">We are always seeking dynamic and motivated professionals in the following roles:</h6>
            <div className="role-cnt">
              <div className="single-cnt">
                <span className="red-dot"></span>
                <div className="text">
                  <h6 className="title">Analysts</h6>
                  <p className="p-text">Candidates with 2-3 years of experience in research or consulting, who are skilled in data analysis and problem solving</p>
                </div>
              </div>
              <div className="single-cnt">
                <span className="red-dot"></span>
                <div className="text">
                  <h6 className="title">Associates</h6>
                  <p className="p-text">Individuals with 2-5 years of proven experience in management consulting, capable of delivering strategic solutions</p>
                </div>
              </div>
              <div className="single-cnt">
                <span className="red-dot"></span>
                <div className="text">
                  <h6 className="title">Senior Associates</h6>
                  <p className="p-text">Experienced professionals with 4-8 years in the industry, adept at overseeing complex projects and mentoring a team</p>
                </div>
              </div>
              <div className="single-cnt">
                <span className="red-dot"></span>
                <div className="text">
                  <h6 className="title">Engagement Managers</h6>
                  <p className="p-text">Experts with more than 10 years of experience, who have demonstrated success in leading significant consulting engagements and driving business growth</p>
                </div>
              </div>
            </div>
            <p className="p-text">To apply, please email your resume to <a href="mailto:info@consult-ibc.com" className="mail-text">info@consult-ibc.com</a> along with a brief cover letter explaining your interest in the role and how you envision contributing to and growing with IBC</p>
          </div>
        </div>
      </section>

      <section className="diversity-sec sec-padding">
        <img src={topRightLine} alt="Line" className="img-fluid top-right-line" />
        <div className="container">
          <SectionHeading secName="Diversity" secText="Diversity and Inclusion" />
          <p className="p-text text-center">IBC is committed to fostering a diverse and inclusive workplace. We comply with POSH regulations and actively promote diversity through comprehensive training, mentorship programs, and employee networks. Our dedication to these initiatives ensures a respectful and equitable environment, upholding respect and fairness across all levels of our organization.</p>
        </div>
      </section>
    </Layout>
  );
}